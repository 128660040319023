import React, { useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';

import style from './TestAssessment.module.scss';
import { useTranslation } from 'react-i18next';
import { Question } from '../../../types/beyimAssessmentApiType/GetQuestionsAssessmentResponseApiType';
import { indexLatinLetters } from '../../ActivityPost/utils/indexLatinLetters';
import { LexcialEditor } from '../../LexicalEditor/LexcialEditor';
import { ModalComponent } from '../../../ui/ModalComponent';
import classNames from 'classnames';
import { SkeletonQuestion } from '../../../pages/assessment/results/SkeletonQuestion';
import Skeleton from 'react-loading-skeleton';

export interface TestAssessmentProps {
    test: Question[] | undefined;
    currentQuestion: number;
    changeCurrentQuestion: (index: number) => void;
    isLoadingComplete: boolean;
    isLoadingSubmitAnswer: boolean;
    register: any;
    isLoadingQuestions: boolean;
}

export const TestAssessment: React.FC<TestAssessmentProps> = ({
    test,
    currentQuestion,
    changeCurrentQuestion,
    isLoadingComplete,
    isLoadingSubmitAnswer,
    register,
    isLoadingQuestions,
}) => {
    const { t } = useTranslation();
    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
    return (
        <article
            className={classNames( 
                'p-6 sm:rounded-2xl max-w-[592px] w-full mt-2 md:mt-[31px] mx-auto my-0',
                style.passingTest,
            )}
        >
            <header className="flex flex-row justify-between mb-2">
                {isLoadingQuestions ? (
                    <Skeleton
                        width={80}
                        height={20}
                        baseColor="#e9f0f3"
                        borderRadius={10}
                    />
                ) : (
                    <h2
                        className={classNames(
                            `text-base font-bold m-0`,
                            style.count,
                        )}
                    >
                        {t('assessment.question')} {currentQuestion + 1}/
                        {test?.length}
                    </h2>
                )}
            </header>

            <aside>
                {isLoadingQuestions ? (
                    <article className="mb-4">
                        <Skeleton
                            count={1}
                            baseColor="#e9f0f3"
                            height={20}
                            width={80}
                            borderRadius={10}
                            className="mb-2"
                        />

                        <Skeleton
                            count={4}
                            baseColor="#e9f0f3"
                            height={56}
                            borderRadius={10}
                        />
                    </article>
                ) : (
                    test &&
                    test?.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className={`
                                    ${
                                        currentQuestion === index
                                            ? 'block'
                                            : 'hidden'
                                    }`}
                            >
                                <div className="mb-4">
                                    <LexcialEditor fieldData={item.body} />
                                </div>
                                <div className="flex flex-col gap-1 mb-4">
                                    {item.type === 'SS_MCQ' &&
                                        item?.options?.map(
                                            (answer, answerIndex) => {
                                                const id = nanoid();
                                                return (
                                                    <div key={id}>
                                                        <input
                                                            className={classNames(
                                                                'opacity-100 w-0 h-0 hidden',
                                                                style.input,
                                                            )}
                                                            name={`answers[${index}].answer`}
                                                            type="radio"
                                                            id={`${id}-${answerIndex}`}
                                                            value={answerIndex}
                                                            {...register(
                                                                `answers[${index}].answer`,
                                                            )}
                                                        />
                                                        <label
                                                            className={classNames(
                                                                'rounded-xl px-4 py-[14px] font-medium items-center cursor-pointer flex text-base mb-0 md:hover:bg-[#e9f0f3]',
                                                                style.label,
                                                            )}
                                                            htmlFor={`${id}-${answerIndex}`}
                                                        >
                                                            <span
                                                                className={`me-3 font-medium text-base ${style.letter}`}
                                                            >
                                                                {
                                                                    indexLatinLetters[
                                                                        answerIndex
                                                                    ]
                                                                }
                                                            </span>{' '}
                                                            <LexcialEditor
                                                                fieldData={
                                                                    answer.body
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                );
                                            },
                                        )}
                                    {/* {item.type === 'MS_MCQ' &&
                                        item?.options.map(
                                            (answer, answerIndex) => {
                                                const id = nanoid();
                                                return (
                                                    <div key={id}>
                                                        <input
                                                            name={`answers[${index}].answer`}
                                                            type="checkbox"
                                                            id={`${id}-${answerIndex}`}
                                                            value={answerIndex}
                                                            checked={formik.values.answers[
                                                                index
                                                            ]?.answer.includes(
                                                                answerIndex.toString(),
                                                            )}
                                                            onChange={e => {
                                                                const isChecked =
                                                                    e.target
                                                                        .checked;
                                                                const selectedValues =
                                                                    formik
                                                                        .values
                                                                        .answers[
                                                                        index
                                                                    ]?.answer ||
                                                                    [];
                                                                let updatedValues =
                                                                    [];

                                                                if (isChecked) {
                                                                    updatedValues =
                                                                        [
                                                                            ...selectedValues,
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        ];
                                                                } else {
                                                                    updatedValues =
                                                                        selectedValues.filter(
                                                                            (
                                                                                value: any,
                                                                            ) =>
                                                                                value !==
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        );
                                                                }
                                                                formik.setFieldValue(
                                                                    `answers[${index}].answer`,
                                                                    updatedValues,
                                                                );
                                                                formik.setFieldValue(
                                                                    `answer[${index}].id`,
                                                                    item.id,
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`${id}-${answerIndex}`}
                                                        >
                                                            <span>
                                                                {
                                                                    indexLatinletters[
                                                                        answerIndex
                                                                    ]
                                                                }
                                                            </span>{' '}
                                                            <SingleFieldReadEditorWrapper
                                                                fieldData={
                                                                    answer.body
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                );
                                            },
                                        )} */}
                                </div>
                            </div>
                        );
                    })
                )}
                <ModalComponent
                    isOpen={isOpenedModal}
                    onClose={() => {
                        setIsOpenedModal(false);
                    }}
                    headerContent={
                        <div className=" flex justify-center flex-col items-center">
                            <img
                                src="/icons/redLight.svg"
                                alt="redLight"
                                className="w-[32px] h-[32px] mb-3"
                            />
                            <span
                                className={classNames(
                                    'm-0 text-lg font-bold text-center',
                                    style.modal__header,
                                )}
                            >
                                {t('assessment.timeLeftForTest')}
                            </span>
                        </div>
                    }
                    content={
                        <p
                            className={classNames(
                                'm-0 text-base text-center leading-[22.4px] font-medium',
                                style.modal__text,
                            )}
                        >
                            {t('assessment.confirmFinishTest')}?
                        </p>
                    }
                    customContent={
                        <footer className="flex justify-between gap-4 w-full">
                            <button
                                type="button"
                                className={`w-full whitespace-nowrap text-center text-sm font-semibold block py-[14px] px-6 rounded-2xl ${style.button_disabled} `}
                                onClick={() => {
                                    setIsOpenedModal(false);
                                }}
                            >
                                {t('assessment.continueTest')}
                            </button>
                            <button
                                type="submit"
                                className={`w-full whitespace-nowrap rounded-2xl text-center text-sm font-semibold block px-6 py-[14px] ${style.footer__button} ${style.footer__button__simple}`}
                            >
                                {isLoadingSubmitAnswer || isLoadingComplete ? (
                                    <div
                                        className={classNames(
                                            'w-5 h-5 rounded-[50%] my-0 mx-auto',
                                            style.spinner,
                                        )}
                                    />
                                ) : (
                                    t('assessment.finishTest')
                                )}
                            </button>
                        </footer>
                    }
                />
            </aside>
            <footer
                className={classNames(
                    'md:flex gap-[16px] justify-between items-center sm:gap-[313px] flex-grow-0 hidden',
                    style.footer,
                )}
            >
                <button
                    type="button"
                    className={`w-full whitespace-nowrap rounded-2xl text-center text-sm font-semibold block px-6 py-[14px] ${style.footer__button} ${style.footer__button__simple}`}
                    style={{
                        display: currentQuestion === 0 ? 'none' : 'block',
                    }}
                    onClick={() => {
                        if (currentQuestion > 0) {
                            changeCurrentQuestion(currentQuestion - 1);
                        }
                    }}
                >
                    {t('assessment.back')}
                </button>
                <button
                    type="button"
                    className={`w-full whitespace-nowrap rounded-2xl text-center text-sm font-semibold block px-6 py-[14px] ${
                        style.footer__button
                    }  ${
                        test && currentQuestion === test?.length - 1
                            ? style.footer__button__success
                            : style.footer__button__simple
                    } `}
                    onClick={() => {
                        if (test && currentQuestion < test?.length - 1) {
                            changeCurrentQuestion(currentQuestion + 1);
                        }
                        // setIsOpenedModal(true)
                        if (test && currentQuestion === test?.length - 1) {
                            setIsOpenedModal(true);
                        }
                    }}
                >
                    {test && currentQuestion === test?.length - 1
                        ? t('assessment.finish')
                        : t('assessment.next')}
                </button>
            </footer>
            <footer className="fixed bottom-0 left-0  w-full block md:hidden">
                <div className="max-w-[592px] m-auto bg-[#FFF] sm:rounded-2xl">
                    <div className="flex justify-between px-4 py-2 gap-2">
                        <button
                            type="button"
                            className={classNames(
                                `w-full whitespace-nowrap rounded-2xl text-center text-sm font-semibold block px-6 py-[14px] ${style.footer__button} ${style.footer__button__simple}`,
                                currentQuestion === 0 && style.button_disabled,
                            )}
                            onClick={() => {
                                if (currentQuestion > 0) {
                                    changeCurrentQuestion(currentQuestion - 1);
                                }
                            }}
                        >
                            {t('assessment.back')}
                        </button>
                        <button
                            type="button"
                            className={`w-full whitespace-nowrap rounded-2xl text-center text-sm font-semibold block px-6 py-[14px] ${
                                style.footer__button
                            }  ${
                                test && currentQuestion === test?.length - 1
                                    ? style.footer__button__success
                                    : style.footer__button__simple
                            } `}
                            onClick={() => {
                                if (
                                    test &&
                                    currentQuestion < test?.length - 1
                                ) {
                                    changeCurrentQuestion(currentQuestion + 1);
                                }
                                // setIsOpenedModal(true)
                                if (
                                    test &&
                                    currentQuestion === test?.length - 1
                                ) {
                                    setIsOpenedModal(true);
                                }
                            }}
                        >
                            {test && currentQuestion === test?.length - 1
                                ? t('assessment.finish')
                                : t('assessment.next')}
                        </button>
                    </div>
                </div>
            </footer>
        </article>
    );
};
