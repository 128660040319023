import './App.scss';

import Authorization from './pages/authorization';

import { Link, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { login, setRole } from './features/slice/authSlice';
import Feed from './pages/feed';
import More from './pages/feed/more';
import Onboarding from './pages/onboarding';
import Favorites from './pages/favorites';
import Assessment from './pages/assessment';
import { Passing } from './pages/assessment/passing';
import { ResultTest } from './pages/assessment/results';
import { PageNotFound } from './pages/notFound';
import { RedirectAuthorization } from './HOC/RedirectAuthorization';
import Dashboard from './pages/dashboard';
import { MenuMobileBottom } from './features/MenuMobileBottom';

import { useGetUserDataQuery } from './api/authService/authServiceApi';

import { CloseStudentMode } from './features/CloseStudentMode';
import { BeyimModal } from './ui/BeyimModal';
import DetailStudent from './pages/detailStudent';
import DashboardStudent from './pages/dashboardStudent';
import TagManager from 'react-gtm-module';
import { roles } from './features/AuthForms/constants';

function App() {
    const dispatch = useAppDispatch();
    const { data: userData } = useGetUserDataQuery('', {
        skip: !Cookies.get('access_token'),
    });
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-5333X2NW',
            dataLayerName: 'feed',
            events: {
                nameEvents: 'events',
            },
        };
        TagManager.initialize(tagManagerArgs);
    }, []);
    useEffect(() => {
        if (userData?.data.User.id) {
            Cookies.set('user_id', userData?.data.User.id, {
                expires: new Date(new Date().getTime() + 480 * 60 * 1000),
            });
        }
    }, [userData?.data.User.id]);
    useEffect(() => {
        const coocieData = Cookies.get('access_token');
        if (coocieData && userData?.data.User.registrationStatus === 2) {
            dispatch(login());
            if (
                userData.data.User.organizations.some(
                    item =>
                        item.role === roles.CLASS_TEACHER ||
                        item.role === roles.SUBJECT_TEACHER,
                )
            ) {
                dispatch(setRole('teacher'));
            } else if (
                userData.data.User.organizations.some(
                    item => item.role === roles.STUDENT,
                )
            ) {
                dispatch(setRole('student'));
            }
            dispatch(setRole('student'));
        }
    }, [userData]);

    const isLogin = useAppSelector(state => state.auth.isLogin);
    const role = useAppSelector(state => state.auth.role);
    const root = document.documentElement;
    const i18nextLng = localStorage.getItem('i18nextLng');
    if (i18nextLng === 'ar') {
        root.style.setProperty('--location-support-left', '20px');
        root.style.setProperty('--location-support-right', 'auto');
    } else {
        root.style.setProperty('--location-support-right', '20px');
        root.style.setProperty('--location-support-left', 'auto');
    }

    // useEffect(() => {
    //     // Функция для предотвращения выделения текста
    //     const preventSelectStart = (e: { preventDefault: () => void }) => {
    //         e.preventDefault();
    //     };

    //     // Функция для предотвращения копирования текста
    //     const preventCopy = (e: { preventDefault: () => void }) => {
    //         e.preventDefault();
    //     };

    //     // Добавление обработчиков событий
    //     document.addEventListener('selectstart', preventSelectStart);
    //     document.addEventListener('copy', preventCopy);

    //     // Очистка: Удаление обработчиков событий при размонтировании компонента
    //     return () => {
    //         document.removeEventListener('selectstart', preventSelectStart);
    //         document.removeEventListener('copy', preventCopy);
    //     };
    // }, []);

    const isTeacher = userData?.data.User.organizations.some(
        item =>
            item.role === roles.CLASS_TEACHER ||
            item.role === roles.SUBJECT_TEACHER,
    );

    return (
        <>
            <Routes>
                <Route path="/">
                    <Route
                        index
                        element={
                            <RedirectAuthorization>
                                <Authorization />
                            </RedirectAuthorization>
                        }
                    />
                    <Route path="/dashboard">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <Dashboard />
                                </RedirectAuthorization>
                            }
                        />
                    </Route>
                    <Route path="/dashboard-student">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <DashboardStudent />
                                </RedirectAuthorization>
                            }
                        />
                    </Route>
                    <Route path="/detail-student">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <DetailStudent />
                                </RedirectAuthorization>
                            }
                        />
                    </Route>
                    <Route path="/onboarding">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <Onboarding />
                                </RedirectAuthorization>
                            }
                        />
                    </Route>
                    <Route path="/authorization" element={<Authorization />} />
                    <Route
                        path="/favorites"
                        element={
                            <RedirectAuthorization>
                                <Favorites />
                            </RedirectAuthorization>
                        }
                    />
                    <Route path="/feed">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <Feed />
                                </RedirectAuthorization>
                            }
                        />
                        {/* <Route path="/favorites" element={<div>xxx</div>} /> */}
                    </Route>
                    <Route path="/feed/assessment/:id">
                        <Route
                            index
                            element={
                                <RedirectAuthorization>
                                    <Assessment />
                                </RedirectAuthorization>
                            }
                        />
                        <Route
                            path="passing/:progressId"
                            element={
                                <RedirectAuthorization>
                                    <Passing />
                                </RedirectAuthorization>
                            }
                        />
                        <Route
                            path="results/:progressId"
                            element={
                                <RedirectAuthorization>
                                    <ResultTest />
                                </RedirectAuthorization>
                            }
                        />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <RedirectAuthorization>
                                <PageNotFound />
                            </RedirectAuthorization>
                        }
                    />
                </Route>
            </Routes>
            {isLogin && <MenuMobileBottom />}
            {isTeacher && <CloseStudentMode />}
            <BeyimModal />
        </>
    );
}

export default App;
