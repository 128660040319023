import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';

export const ChatBot = () => {
    const ref = useRef(null);
    const isLogin = useAppSelector(state => state.auth.isLogin);
    const role = useAppSelector(state => state.auth.role);
    const locale = useNormalizedLocale();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState<
        null | undefined | number
    >(undefined);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        if (ref.current && isLogin) {
            script.type = 'module';
            script.text = `
                import Chatbot from "https://cdn.jsdelivr.net/gh/nisbeyim/FlowiseChatEmbed/cdn/web_v16.js";

                
                var path = window.location.pathname
                var welcomeMessage = ''
                var placeholder = ''
                var locale = '${locale}'
                switch (locale) {
                    case 'kk':
                        welcomeMessage = Chatbot.data.welcome_message.kk;
                        placeholder = "Сұрағыңызды жазыңыз";
                        break;
                    case 'en':
                        welcomeMessage = Chatbot.data.welcome_message.en;
                        placeholder = "Ask your question";
                        break;
                    case 'ru':
                        welcomeMessage = Chatbot.data.welcome_message.ru;
                        placeholder = "Напишите свой вопрос";
                        break;
                    case 'ar':
                        welcomeMessage = Chatbot.data.welcome_message.ar;
                        placeholder = "اطرح سؤالك";
                        break;
                    case 'ko':
                        welcomeMessage = Chatbot.data.welcome_message.ko;
                        placeholder = "질문 쓰기";
                        break;
                    default:
                        welcomeMessage = Chatbot.data.welcome_message.kk;
                        placeholder = "Сұрағыңызды жазыңыз";
                        break;
                }

                Chatbot.init({
                    chatflowid: "afe71e95-3878-4142-8a58-ed756ac625a0",
                    apiHost: '${process.env.REACT_APP_CHAT_BOT}',
                    theme: {
                        button: {
                            backgroundColor: "#55BBEB",
                            right: 0,
                            bottom: 15,
                            size: 60,
                            iconColor: "white",
                            customIconSrc: Chatbot.data.avatar_icon,
                        },
                        chatWindow: {
                            welcomeMessage,
                            backgroundColor: "#CBDCE7",
                            height: 600,    
                            width: 400,
                            fontSize: 14,
                            poweredByTextColor: "#303235",
                            botMessage: {
                                backgroundColor: "#FFF",
                                textColor: "#303235",
                                showAvatar: true,
                                avatarSrc: Chatbot.data.avatar_url,
                            },
                            userMessage: {
                                backgroundColor: "#577487",
                                textColor: "#ffffff",
                                showAvatar: false,
                            },
                            textInput: {
                                placeholder,
                                backgroundColor: "#ffffff",
                                textColor: "#303235",
                                sendButtonColor: "#55BBEB",
                            }
                        }
                    }
                })
            `;
            //@ts-ignore
            ref.current.appendChild(script);
        }
        return () => {
            if (ref.current && isLogin) {
                //@ts-ignore
                ref.current?.removeChild(script);
            }

            for (
                let i = 0;
                i < document.getElementsByTagName('flowise-chatbot').length;
                i++
            ) {
                document.getElementsByTagName('flowise-chatbot')[i].remove();
            }
        };
    }, [isLogin, locale]);

    return <div ref={ref}></div>;
};
