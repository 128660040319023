/* eslint-disable no-debugger */
import { Suspense, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from 'react-helmet';

import style from './Authorization.module.scss';
import classNames from 'classnames';

// import required modules
import { Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { ChangingLanguage } from '../../features/ChangingLanguage';

import { useAppSelector } from '../../hooks';

import { Navigate, useSearchParams } from 'react-router-dom';

import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { PersonalTeacher } from '../../features/AuthForms/UpdatingTeacherData';

import { states } from '../../features/auth/constants';
import { LoginForm } from '../../features/auth/Forms/LoginForm';
import { FormText } from '../../features/auth/FormText';
import { useGetTitleAndDescription } from '../../features/auth/hooks/useGetTitleAndDescription';
import { HeaderFormRight } from '../../features/auth/HeaderFormRight';
import { TypeUser } from '../../features/auth/TypeUserComponent';
import { CreatePasswordForm } from '../../features/auth/Forms/CreatePasswordForm';

import {
    ClassCodeCheck,
    OrganizationCodeCheck,
} from '../../features/auth/CodeCheck';

import { Organization } from '../../types/organizationsApiType/GetCheckCodeTeacherResponseApiType';
import { Class } from '../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';
import { CollectingMail } from '../../features/auth/Forms/CollectingMail';
import { Registration } from '../../features/auth/Forms/PersonalStudentAndOrganization';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import Cookies from 'js-cookie';
import { roles } from '../../features/AuthForms/constants';

const Authorization: React.FC = () => {
    const { t, i18n } = useTranslation();

    const [state, setState] = useState<states>(states.login);
    const [activeType, setActiveType] = useState<number>();
    const [organizationData, setOrganizationData] = useState<Organization>();
    const [classData, setClassData] = useState<Class>();

    const [searchParams, setSearchParams] = useSearchParams();
    const locale = useNormalizedLocale();

    const { title, description } = useGetTitleAndDescription(
        t,
        state,
        activeType,
    );
    const { data } = useGetUserDataQuery('', {
        skip: !Cookies.get('access_token'),
    });
    useEffect(() => {
        const lng = searchParams.get('lng');
        const code = searchParams.get('code');

        if (
            lng &&
            (lng === 'ru' ||
                lng === 'en' ||
                lng === 'ar' ||
                lng === 'kk' ||
                lng === 'ko')
        ) {
            i18n.changeLanguage(lng);
            if (code) {
                setSearchParams(prev => {
                    return {
                        code: prev.get('code') || '',
                    };
                });
            } else {
                setSearchParams({});
            }
        }
    }, [searchParams.get('lng')]);

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '"></span>';
        },
    };

    useEffect(() => {
        document.title = t('pages.authorization');
    }, [locale]);
    return (
        <>
            <Helmet>
                <title>{t('pages.authorization')}</title>
                <meta name="description" content="" />
            </Helmet>
            <Suspense fallback="...loading">
                <div className="flex min-h-screen w-screen md:max-w-[100vw] autorization top-0 left-0 absolute ">
                    <div
                        className={classNames(
                            'basis-6/12 grow-0  max-w-[50%] min-h-full',
                            style.slideBlock,
                        )}
                    >
                        <Swiper
                            className={style.wrapperSlider}
                            slidesPerView={1}
                            pagination={pagination}
                            modules={[Pagination]}
                            // onSwiper={(swiper: any) => console.log(swiper)}
                        >
                            {locale === 'ar' ? (
                                <>
                                    {/* <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide1,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="max-w-[272px] mb-[103px]">
                                                    <img
                                                        //@ts-ignore
                                                        // eslint-disable-next-line react/no-unknown-property
                                                        fetchpriority="high"
                                                        width={272}
                                                        height={254}
                                                        alt="imgPlatform1"
                                                        className="block"
                                                        src={`/images/imgPlatform1${locale}.webp`}
                                                    />
                                                </div>
                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide> */}
                                    <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide2,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="max-w-[272px] mb-[103px]">
                                                    <img
                                                        width={272}
                                                        height={292}
                                                        alt="sliderLogin2"
                                                        src={`/images/sliderLogin2.webp`}
                                                    />
                                                </div>

                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    {/* <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide3,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="w-full mb-[103px]">
                                                    <img
                                                        width={448}
                                                        height={308}
                                                        alt="sliderLogin3"
                                                        src={`/images/sliderLogin3${locale}.webp`}
                                                    />
                                                </div>
                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide> */}
                                </>
                            ) : (
                                <>
                                    <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide1,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="max-w-[272px] mb-[103px]">
                                                    <img
                                                        //@ts-ignore
                                                        // eslint-disable-next-line react/no-unknown-property
                                                        fetchpriority="high"
                                                        width={272}
                                                        height={254}
                                                        alt="imgPlatform1"
                                                        className="block"
                                                        src={`/images/imgPlatform1${locale}.webp`}
                                                    />
                                                </div>
                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block min-h-20">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide2,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="max-w-[272px] mb-[103px]">
                                                    <img
                                                        width={272}
                                                        height={292}
                                                        alt="sliderLogin2"
                                                        src={`/images/sliderLogin2.webp`}
                                                    />
                                                </div>

                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block min-h-20">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div
                                            className={classNames(
                                                style.slide,
                                                style.slide3,
                                                ' flex items-center px-4',
                                            )}
                                        >
                                            <div className="max-w-md mx-auto relative z-10">
                                                <div className="w-full mb-[103px]">
                                                    <img
                                                        width={448}
                                                        height={308}
                                                        alt="sliderLogin3"
                                                        src={`/images/sliderLogin3${locale}.webp`}
                                                    />
                                                </div>
                                                <span className="max-w-md text-[32px] leading-[normal] font-bold mb-2 block min-h-20">
                                                    {t(
                                                        'authorization.slide1.title',
                                                    )}
                                                </span>
                                                <p className="max-w-md text-base">
                                                    {t(
                                                        'authorization.slide1.subTitle',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </>
                            )}
                        </Swiper>
                    </div>
                    <div className="py-10 min-h-full basis-6/12 grow flex flex-col justify-between items-center px-4">
                        <header
                            className={classNames(
                                'flex justify-between max-w-[464px] w-full mb-[100px]',
                                style.header,
                            )}
                        >
                            {/* <div className="max-w-[131px]">
                                <a
                                    href={`https://beyim.ai/${
                                        locale === 'kk'
                                            ? ''
                                            : locale === 'en'
                                              ? '/en'
                                              : '/ru'
                                    }`}
                                >
                                    <img
                                        width={131}
                                        height={24}
                                        src="/icons/brandlogo.svg"
                                        alt="brandlogo"
                                        className="flex w-full"
                                    />
                                </a>
                            </div> */}
                            <div>
                                <ChangingLanguage onlyDesktop={true} />
                            </div>
                            <HeaderFormRight
                                setState={setState}
                                state={state}
                            />
                        </header>
                        <main
                            className={classNames(
                                style.main,
                                'max-w-[464px] w-full',
                            )}
                        >
                            <FormText
                                setOrganizationData={setOrganizationData}
                                setClassData={setClassData}
                                organizationData={organizationData}
                                classData={classData}
                                title={title}
                                description={description}
                                setState={setState}
                                state={state}
                            />

                            {state === states.login && (
                                <LoginForm setState={setState} />
                            )}

                            {state === states.type_user && (
                                <TypeUser
                                    setState={setState}
                                    activeType={activeType}
                                    setActiveType={setActiveType}
                                />
                            )}
                            {state === states.change_password && (
                                <CreatePasswordForm setState={setState} />
                            )}

                            {state === states.enter_code &&
                                activeType === 0 && (
                                    <ClassCodeCheck
                                        setState={setState}
                                        setClassData={setClassData}
                                    />
                                )}
                            {state === states.enter_code &&
                                activeType === 1 && (
                                    <OrganizationCodeCheck
                                        setState={setState}
                                        setOrganizationData={
                                            setOrganizationData
                                        }
                                    />
                                )}
                            {state === states.create_mail && (
                                <CollectingMail
                                    activeType={activeType}
                                    setState={setState}
                                    organizationData={organizationData}
                                    classData={classData}
                                />
                            )}
                            {state === states.personal_data &&
                                data?.data.User.organizations?.some(
                                    item =>
                                        item.role === roles.CLASS_TEACHER ||
                                        item.role === roles.SUBJECT_TEACHER,
                                ) && <PersonalTeacher />}
                            {state === states.personal_data &&
                                data?.data.User.organizations?.find(
                                    item =>
                                        item.role !== roles.CLASS_TEACHER &&
                                        item.role !== roles.SUBJECT_TEACHER,
                                ) && <Registration />}
                        </main>
                        <footer className={style.footer}>
                            <span className="flex gap-x-1 mt-4">
                                <img
                                    src="/icons/nis.svg"
                                    width={22}
                                    height={25}
                                    alt="nis"
                                />
                                <span>© 2024 BeyimTech</span>
                            </span>
                        </footer>
                    </div>
                </div>
            </Suspense>
        </>
    );
};

export default Authorization;
