import classNames from 'classnames';
import style from './LoginForm.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '../../../../ui';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
    useAppDispatch,
    useAppSelector,
    useNormalizedLocale,
} from '../../../../hooks';
import { usePostLoginMutation } from '../../../../api';
import * as yup from 'yup';
import { PostAuthRequestApiType } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { login, setRole } from '../../../slice/authSlice';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { ModalComponent } from '../../../../ui/ModalComponent';
import { states } from '../../constants';
import {
    useLazyGetUserDataQuery,
    usePostAuthADLoginMutation,
} from '../../../../api/authService/authServiceApi';
import { expires } from '../../../../constants/expires';
import { roles } from '../../../AuthForms/constants';

const authSchema = yup
    .object({
        username: yup.string().required().email(),
        password: yup.string().required(),
    })
    .required();

interface ILoginFormProps {
    setState: (state: states) => void;
}
export const LoginForm: React.FC<ILoginFormProps> = ({ setState }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const locale = useNormalizedLocale();
    const isKK = locale === 'kk';
    const [buttonClass, setButtonClass] = useState<string>(style.enter);
    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isFirst, setIsFirst] = useState<undefined | boolean>(undefined);
    const [postLogin, { isLoading }] = usePostLoginMutation();
    const [postLoginAD] = usePostAuthADLoginMutation();
    const [getUserData, { isLoading: isLoginUserData }] =
        useLazyGetUserDataQuery();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setError,
        watch,
    } = useForm<PostAuthRequestApiType>({
        resolver: yupResolver(authSchema),
    });
    const watchAllFields = watch();
    const isLogin = useAppSelector(state => state.auth.isLogin);
    const role = useAppSelector(state => state.auth.role);
    useEffect(() => {
        setButtonClass(isValid ? style.validated : style.enter);
    }, [isValid, watchAllFields]);

    const navigationForStudent = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const onBoarding = localStorage.getItem('onBoarding');
        if (onBoarding) {
            navigate('/feed');
            setIsFirst(false);
        }
        if (width > 1150 && height > 950 && !onBoarding) {
            navigate('/onboarding');
            setIsFirst(true);
        } else {
            navigate('/feed');
            setIsFirst(false);
        }

        width > 1150 &&
            height > 950 &&
            localStorage.setItem('onBoarding', 'true');
    };
    const navigationForTeacher = () => {
        setIsFirst(false);
        navigate('/dashboard');
    };

    const navigateRole = () => {
        getUserData('')
            .unwrap()
            .then(data => {
                Cookies.set('user_id', data?.data.User.id, {
                    expires: expires,
                });
                if (data.data.User.registrationStatus === 2) {
                    dispatch(login());
                    if (
                        data.data.User.organizations.some(
                            user =>
                                user.role === roles.CLASS_TEACHER ||
                                user.role === roles.SUBJECT_TEACHER,
                        )
                    ) {
                        dispatch(setRole('teacher'));
                        setIsFirst(false);
                        navigationForTeacher();
                    } else if (
                        data.data.User.organizations.some(
                            user => user.role === roles.STUDENT,
                        )
                    ) {
                        dispatch(setRole('student'));
                        navigationForStudent();
                    } else {
                        dispatch(setRole('student'));
                        navigationForStudent();
                    }
                } else if (data.data.User.registrationStatus === 1) {
                    setState(states.personal_data);
                } else {
                    dispatch(login());
                    dispatch(setRole('student'));
                    navigationForStudent();
                }
            });
    };

    const onSubmit = (data: PostAuthRequestApiType) => {
        postLogin(data)
            .unwrap()
            .then(data => {
                Cookies.set('access_token', data.data.tokenData.access_token, {
                    expires,
                    // secure: true,
                });
                Cookies.set('id_token', data.data.tokenData.id_token, {
                    expires,
                    // secure: true,
                    // domain:
                });
                Cookies.set(
                    'refresh_token',
                    data.data.tokenData.refresh_token,
                    {
                        expires: 30,
                        // secure: true,
                    },
                );
            })
            .then(() => {
                navigateRole();
            })
            .catch(() => {
                setError('username', {
                    type: '400',
                    message: t('authorization.invalidLogin'),
                });
                setError('password', {
                    type: '400',
                    message: t('authorization.invalidLogin'),
                });
            });
    };

    useEffect(() => {
        if (searchParams.get('code') && !searchParams.get('username')) {
            postLoginAD(searchParams.get('code') || '')
                .unwrap()
                .then(data => {
                    Cookies.set('access_token', data.access_token, {
                        expires: expires,
                        // secure: true,
                    });
                    Cookies.set('id_token', data.id_token, {
                        expires: expires,
                        // secure: true,
                    });
                    Cookies.set('refresh_token', data.refresh_token, {
                        expires: 30,
                        // secure: true,
                    });
                    // eslint-disable-next-line no-debugger
                })
                .then(() => {
                    navigateRole();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [searchParams.get('code')]);

    const hardCodedLogin = () => {
        postLogin({
            username: searchParams.get('username') as string,
            password: searchParams.get('code') as string,
        })
            .unwrap()
            .then(data => {
                localStorage.setItem('session', data.data.tokenData.session);
                localStorage.setItem(
                    'challenge_name',
                    data.data.tokenData.challenge_name,
                );
                setState(states.change_password);
            });
    };

    useEffect(() => {
        if (searchParams.get('username') && searchParams.get('code'))
            hardCodedLogin();
    }, []);
    const roleToPathMap = {
        student: '/feed',
        teacher: '/dashboard',
    };
    return (
        <>
            {isLogin &&
                role &&
                isFirst !== undefined &&
                (isFirst && role === 'student' ? (
                    navigationForStudent()
                ) : (
                    <Navigate to={roleToPathMap[role]} />
                ))}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder={t('authorization.placeholderUsername')}
                    label={t('authorization.username')}
                    register={register}
                    type="email"
                    name="username"
                    className="mb-4"
                    error={
                        errors.username?.message &&
                        t('authorization.loginError')
                    }
                />
                <div className="relative">
                    <Input
                        label={t('authorization.password')}
                        type={showPassword ? '' : 'password'}
                        name="password"
                        register={register}
                        className="mb-3"
                        placeholder={t('authorization.placeholdePasword')}
                        error={
                            errors.password?.message &&
                            t('authorization.loginError')
                        }
                    />
                    <img
                        onClick={() => setShowPassword(!showPassword)}
                        className={classNames(
                            'absolute top-[47px]  transform -translate-y-1/2 cursor-pointer',
                            style.iconShow,

                            locale === 'ar' ? 'left-6' : 'right-6',
                        )}
                        src={
                            showPassword
                                ? '/icons/visible-password-active.svg'
                                : '/icons/visible-password.svg'
                        }
                        alt="Toggle password visibility"
                    />
                </div>
                <button
                    type="button"
                    className={classNames(
                        style.forgetPasword,
                        'text-sm block ms-auto mb-6 ',
                    )}
                    onClick={() => setIsOpenedModal(true)}
                >
                    {t('authorization.forgetPasword')}
                </button>
                <ModalComponent
                    isOpen={isOpenedModal}
                    onClose={() => {
                        setIsOpenedModal(false);
                    }}
                    classNameTitle="modal__header"
                    classNameContent="modal__content"
                    headerContent={
                        <button
                            className={`flex p-1 rounded-full self-end ml-auto ${style.modalButton}`}
                            type="button"
                            onClick={() => {
                                setIsOpenedModal(false);
                            }}
                        >
                            <img
                                src="/icons/closeWhite.svg"
                                className="w-4 h-4"
                            />
                        </button>
                    }
                    content={
                        <div className=" text-center  flex flex-col gap-2">
                            <span className="flex justify-center">
                                <img
                                    alt="forgotPasswordIcon"
                                    src="/icons/forgotPasswordIcon.svg"
                                    className="w-13 h-13"
                                />
                            </span>
                            <h3 className=" text-base font-bold leading-[22.4px] mt-2">
                                {t('authorization.forgetPaswordModal.header')}
                            </h3>
                            <p className=" text-[14px] leading-5 px-2">
                                {t(
                                    'authorization.forgetPaswordModal.description',
                                )}
                            </p>
                        </div>
                    }
                    customContent={<></>}
                />
                <button
                    type="submit"
                    disabled={!isValid}
                    className={classNames(
                        'min-h-[52px] w-full rounded-xl text-[17px] font-medium duration-200 mb-[105px]',
                        style.enter,
                        buttonClass,
                    )}
                >
                    {isLoading || isLoginUserData ? (
                        <div
                            className={classNames(
                                'w-5 h-5 rounded-[50%] my-0 mx-auto',
                                style.spinner,
                                buttonClass,
                            )}
                        />
                    ) : (
                        t('authorization.enter')
                    )}
                </button>
            </form>
            <div>
                <a
                    className={classNames(
                        'min-h-[52px] w-full rounded-xl duration-200 mb-[8px] flex justify-center gap-x-2 items-center ',
                        style.ad,
                    )}
                    href={`https://beyim-users.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=NIS-AD&response_type=CODE&client_id=${process.env.REACT_APP_AD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MAIN}/authorization/`}
                >
                    <div className="flex flex-wrap w-6 gap-[1px]">
                        <span
                            className={classNames(
                                'red w-[11.5px] h-[11.5px]',
                                style.red,
                            )}
                        ></span>
                        <span
                            className={classNames(
                                'red w-[11.5px] h-[11.5px]',
                                style.green,
                            )}
                        ></span>
                        <span
                            className={classNames(
                                'red w-[11.5px] h-[11.5px]',
                                style.blue,
                            )}
                        ></span>
                        <span
                            className={classNames(
                                'red w-[11.5px] h-[11.5px]',
                                style.yellow,
                            )}
                        ></span>
                    </div>
                    <span className=" font-medium ">
                        {t('authorization.enterAD')}
                    </span>
                </a>
                <a
                    className={classNames(
                        'min-h-[52px] w-full rounded-xl duration-200 mb-[35px] flex justify-center gap-x-2 items-center ',
                        style.ad,
                    )}
                    href={`https://app.beyim.ai/${
                        locale === 'ar' ? 'en' : locale
                    }/authorization/`}
                >
                    <span className=" font-medium ">
                        {isKK
                            ? `${t('authorization.logAsAdmin')}/${t(
                                  'authorization.logAsAuthor',
                              )}`
                            : `${t('authorization.logAsAuthor')}/${t(
                                  'authorization.logAsAdmin',
                              )}`}
                    </span>
                </a>
            </div>
        </>
    );
};
