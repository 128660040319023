import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { roles } from '../constants';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import style from './PersonalTeacher.module.scss';
import { useTranslation } from 'react-i18next';
import {
    useGetAllSubjectsQuery,
    useLazyGetAllSubjectsQuery,
} from '../../../api';
import { useAppDispatch, useNormalizedLocale } from '../../../hooks';
import { LabelLayout } from '../components/LabelLayout';
import { Button } from '../components/ButtonComponent';
import {
    useLazyGetAllClassesQuery,
    useLazyGetAllSchoolsQuery,
} from '../../../api/organizationService';
import { SelectBeyim } from '../../../ui/SelectBeyim/SelectBeyim';
import {
    useGetUserDataQuery,
    useUpdateUserDataMutation,
} from '../../../api/authService/authServiceApi';
import { useNavigate } from 'react-router-dom';
import { login, setRole } from '../../slice/authSlice';

interface IFormInput {
    school: { value: number; label: string };
    firstName: string;
    lastName: string;
    courseSubject: { value: string; label: string }[];
    class: { value: string; label: string }[];
}

export const PersonalTeacher = () => {
    const locale = useNormalizedLocale();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [checkedSubject, setCheckedSubject] = useState<boolean>(false);

    const [checkedClass, setCheckedClass] = useState<boolean>(false);
    const [classesOptions, setClassesOptions] = useState<
        {
            value: number | undefined;
            label: string | undefined;
        }[]
    >([]);
    const [errorCheckbox, setErrorCheckbox] = useState<boolean>(false);

    const { data: userData } = useGetUserDataQuery('');
    const { data: subjects } = useGetAllSubjectsQuery({});
    const [getSchools, { data: schools }] = useLazyGetAllSchoolsQuery();
    const [getClasses] = useLazyGetAllClassesQuery();
    const [updateUser] = useUpdateUserDataMutation();
    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        getValues,
        formState: { errors, isValid },
    } = useForm<IFormInput>({
        defaultValues: {
            firstName: userData?.data.User.firstName,
            lastName: userData?.data.User.secondName,
            courseSubject: [],
            class: [],
        },
    });
    useEffect(() => {
        if (!userData?.data.User.organizations[0]?.organizationId) return;
        getSchools({
            organizationId:
                userData?.data.User.organizations[0]?.organizationId.toString() ??
                '',
        });
    }, [userData]);

    useEffect(() => {
        if (!watch('school')) return;
        getClasses({ schoolId: watch('school').value })
            .unwrap()
            .then(res => {
                setClassesOptions(
                    res.data.classes.map(classItem => ({
                        value: classItem.id,
                        label: classItem.grade.number + classItem.letter,
                    })),
                );
            });
    }, [watch('school')]);

    const onSubmit = (data: any) => {
        if (!checkedSubject && !checkedClass) {
            setErrorCheckbox(true);
            return;
        }
        setErrorCheckbox(false);

        const updateData2 = {
            firstName: data.firstName,
            secondName: data.lastName,
            organizations: [
                data.courseSubject.length > 0 && {
                    id: userData?.data.User.organizations.find(item => {
                        if (item.role === roles.SUBJECT_TEACHER) {
                            return item.id;
                        }
                    })?.id,
                    organizationId: userData?.data.User.organizations.find(
                        item => {
                            if (item.role === roles.SUBJECT_TEACHER) {
                                return item.organizationId;
                            }
                        },
                    )?.organizationId,

                    role: roles.SUBJECT_TEACHER,
                    schools: [
                        {
                            schoolId: watch('school').value,
                            subjects: data.courseSubject.map(
                                (subject: any) => ({
                                    subjectId: subject.value,
                                }),
                            ),
                        },
                    ],
                },
                data.class.length > 0 && {
                    id: userData?.data.User.organizations.find(item => {
                        if (item.role === roles.CLASS_TEACHER) {
                            return item.id;
                        }
                    })?.id,

                    organizationId: userData?.data.User.organizations.find(
                        item => {
                            if (item.role === roles.CLASS_TEACHER) {
                                return item.organizationId;
                            }
                        },
                    )?.organizationId,

                    role: roles.CLASS_TEACHER,
                    schools: [
                        {
                            schoolId: watch('school').value,
                            classes: data.class.map((classItem: any) => ({
                                classId: classItem.value,
                            })),
                        },
                    ],
                },
            ].filter(Boolean),
        };
        console.log(updateData2, 123);

        updateUser(updateData2)
            .unwrap()
            .then(() => {
                toast.success(t('authorization.personal.successPersonal'));
                dispatch(login());
                dispatch(setRole('teacher'));
                navigate('/dashboard');
            })
            .catch(() => {
                reset();
                toast.error(t('authorization.personal.errorPersonal'));
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap justify-between gap-6">
                <LabelLayout
                    labelText={t('authorization.personal.firstName') + '*'}
                    className="md:max-w-[220px]"
                >
                    <input
                        {...register('firstName', {
                            required: t(
                                'authorization.personal.firstNameRequired',
                            ),
                        })}
                        className={classNames(
                            'rounded-2xl text-sm h-[56px] text-[#232F37] px-3',
                            style.input,
                        )}
                        placeholder={t(
                            'authorization.personal.firstNamePlaceholder',
                        )}
                        autoComplete="off"
                    />
                    {errors.firstName && (
                        <p className="text-red-500 text-xs mt-1">
                            {errors.firstName.message}
                        </p>
                    )}
                </LabelLayout>

                <LabelLayout
                    labelText={t('authorization.personal.lastName') + '*'}
                    className="md:max-w-[220px]"
                >
                    <input
                        {...register('lastName', {
                            required: t(
                                'authorization.personal.lastNameRequired',
                            ),
                        })}
                        className={classNames(
                            'rounded-2xl text-sm text-[#232F37] h-[56px] px-3',
                            style.input,
                        )}
                        placeholder={t(
                            'authorization.personal.lastNamePlaceholder',
                        )}
                        autoComplete="off"
                    />
                    {errors.lastName && (
                        <p className="text-red-500 text-xs mt-1">
                            {errors.lastName.message}
                        </p>
                    )}
                </LabelLayout>
                <LabelLayout
                    labelText={t('authorization.personal.school') + '*'}
                    className="md:max-w-[220px]"
                >
                    <Controller
                        rules={{
                            required: t(
                                'authorization.personal.schoolRequired',
                            ),
                        }}
                        control={control}
                        name="school"
                        render={({ field }) => (
                            <SelectBeyim
                                {...field}
                                className="rounded-2xl"
                                placeholder={t(
                                    'authorization.personal.selectSchool',
                                )}
                                //@ts-ignore
                                options={schools?.data.schools.map(school => ({
                                    value: school.id,
                                    label:
                                        school.translations.find(
                                            translation =>
                                                translation.locale === locale,
                                        )?.shortName ??
                                        school.translations[0].shortName,
                                }))}
                                size="xl"
                            />
                        )}
                    />
                    {errors.school && (
                        <p className="text-red-500 text-xs mt-1">
                            {errors.school.message}
                        </p>
                    )}
                </LabelLayout>

                <LabelLayout
                    labelText={t('authorization.personal.selectRole') + '*'}
                    classNameLabel="!mb-5"
                >
                    <div className="flex flex-col gap-1">
                        <div
                            className={classNames(
                                'flex flex-col gap-2',
                                checkedSubject &&
                                    'bg-[#F4F7F9] py-2 rounded-xl px-1',
                                style.typeFilter,
                            )}
                        >
                            <div className="flex flex-row items-center gap-2 px-2 py-1">
                                <input
                                    className="hidden"
                                    type="checkbox"
                                    id="subject"
                                    onChange={e => {
                                        setCheckedSubject(e.target.checked);
                                    }}
                                />
                                <label
                                    htmlFor="subject"
                                    className={classNames(
                                        'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            ' block w-6 h-6 border-2 rounded bg-center',
                                        )}
                                    />
                                </label>
                                <span className="text-[#364954] font-medium text-base leading-[22.4px]">
                                    {t('authorization.personal.subjectTeacher')}
                                </span>
                            </div>
                            <LabelLayout
                                labelText={
                                    t('authorization.personal.whatSubject') +
                                    '*'
                                }
                                className={classNames(
                                    'mt-4',
                                    checkedSubject ? 'block' : 'hidden',
                                )}
                            >
                                {subjects && (
                                    <Controller
                                        rules={{
                                            validate: () =>
                                                getValues('courseSubject')
                                                    .length === 0 &&
                                                getValues('class').length === 0
                                                    ? t(
                                                          'authorization.personal.subjectIsRequired',
                                                      )
                                                    : true,
                                        }}
                                        control={control}
                                        name="courseSubject"
                                        render={({ field }) => (
                                            <SelectBeyim
                                                {...field}
                                                size="xl"
                                                //@ts-ignore
                                                options={subjects.data.subjects.map(
                                                    subject => ({
                                                        value: subject.id,
                                                        label:
                                                            subject?.translations.find(
                                                                translation =>
                                                                    translation.locale ===
                                                                    locale,
                                                            )?.name ??
                                                            subject
                                                                .translations[0]
                                                                .name,
                                                    }),
                                                )}
                                                isMulti
                                                placeholder={t(
                                                    'authorization.personal.selectSubject',
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </LabelLayout>
                        </div>

                        <div
                            className={classNames(
                                'flex flex-col gap-2',
                                checkedClass &&
                                    'bg-[#F4F7F9] py-2 rounded-xl px-1',
                                style.typeFilter,
                            )}
                        >
                            <div className="flex flex-row items-center gap-2 px-[8px] py-[4px] ">
                                <input
                                    className="hidden"
                                    type="checkbox"
                                    id="class"
                                    onChange={e => {
                                        setCheckedClass(e.target.checked);
                                    }}
                                />
                                <label
                                    htmlFor="class"
                                    className={classNames(
                                        'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            ' block w-6 h-6 border-2 rounded bg-center',
                                        )}
                                    />
                                </label>
                                <span className="text-[#364954] font-medium text-base leading-[22.4px]">
                                    {t('authorization.personal.classTeacher')}
                                </span>
                            </div>

                            <LabelLayout
                                labelText={
                                    t('authorization.personal.whatClass') + '*'
                                }
                                className={classNames(
                                    'mt-[16px]',
                                    checkedClass ? 'block' : 'hidden',
                                )}
                            >
                                <Controller
                                    rules={{
                                        validate: () =>
                                            //@ts-ignore
                                            getValues('courseSubject')
                                                .length === 0 &&
                                            //@ts-ignore
                                            getValues('class').length === 0
                                                ? t(
                                                      'authorization.personal.oneIsRequried',
                                                  )
                                                : true,
                                    }}
                                    name="class"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectBeyim
                                            className={classNames(
                                                checkedClass
                                                    ? 'block'
                                                    : 'hidden',
                                            )}
                                            {...field}
                                            size="xl"
                                            //@ts-ignore
                                            options={classesOptions.filter(
                                                classItem => classItem.value,
                                            )}
                                            isMulti
                                            placeholder={t(
                                                'authorization.personal.selectClass',
                                            )}
                                        />
                                    )}
                                />
                            </LabelLayout>
                        </div>
                    </div>
                    {errorCheckbox || errors.courseSubject || errors.class ? (
                        <p className="text-red-500 text-xs mt-1">
                            {t('authorization.personal.oneIsRequried')}
                        </p>
                    ) : null}
                </LabelLayout>
            </div>
            <div className="mt-6">
                <Button
                    text={t('assessment.next')}
                    type="submit"
                    variant={
                        isValid &&
                        (getValues('courseSubject').length > 0 ||
                            getValues('class').length > 0)
                            ? 'primary'
                            : 'disabled'
                    }
                />
            </div>
        </form>
    );
};
