import { useEffect, useState } from 'react';
import { SelectBeyim } from '../../../../ui/SelectBeyim/SelectBeyim';
import { useGetAllSectionsQuery } from '../../../../api';
import { useNormalizedLocale } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { useGetProgressByUnitQuery } from '../../../../api/beyimProgressDashboard/beyimProgressDashboardApi';
import { AccordionRow } from './utils/AccordionRow';
import { useGetUserDataQuery } from '../../../../api/authService/authServiceApi';
import { startsWithSeven } from '../startsWithSeven/startsWithSeven';

interface IProgressBySection {
    classId: string;
    subjectId: string;
}

export const ProgressBySection: React.FC<IProgressBySection> = ({
    classId,
    subjectId,
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    const [sectionValue, setSectionValue] = useState<{
        value: number;
        label: string;
    } | null>();

    const { data: sections } = useGetAllSectionsQuery({
        type_id: 2,
        limit: 1000,
        subject_id: Number(subjectId) || 3,
    });

    const { data } = useGetUserDataQuery('');

    const isDemo =
        data?.data.User.email === 'quantum_demo_teacher@beyim.ai' ||
        data?.data.User.email === 'binom_demo_teacher@beyim.ai';

    const dataSectionDemo =
        sections?.data.sections.filter(sections => {
            return startsWithSeven(sections.translations[0].name) && isDemo;
        }) || [];

    useEffect(() => {
        if (data) {
            if (isDemo) {
                setSectionValue({
                    value: dataSectionDemo[0]?.id || 0,
                    label:
                        dataSectionDemo[0]?.translations?.find(
                            item => item.locale === locale,
                        )?.name ||
                        dataSectionDemo[0]?.translations?.[0].name ||
                        '',
                });
            } else {
                setSectionValue({
                    value: sections?.data.sections[0]?.id || 0,
                    label:
                        sections?.data.sections[0]?.translations?.find(
                            item => item.locale === locale,
                        )?.name ||
                        sections?.data.sections[0]?.translations?.[0].name ||
                        '',
                });
            }
        }
    }, [sections, locale]);

    const { data: progressByUnit } = useGetProgressByUnitQuery(
        {
            subjectId,
            classId,
            sectionId: sectionValue?.value.toString() || '',
        },
        {
            skip: !classId || !subjectId,
        },
    );

    return (
        <>
            <div className="flex justify-between bg-white px-6 py-3 rounded-tr-2xl ">
                <div className="flex gap-2 flex-wrap w-full">
                    <div className="w-[250px] max-w-full">
                        <SelectBeyim
                            isDisabled={!subjectId}
                            size="xs"
                            placeholder={t('statistics.section') + ': '}
                            value={sectionValue}
                            isPrefix={true}
                            options={
                                isDemo
                                    ? dataSectionDemo.map(section => ({
                                          value: section.id,
                                          label:
                                              section.translations?.find(
                                                  item =>
                                                      item.locale === locale,
                                              )?.name ??
                                              section.translations?.[0].name,
                                      }))
                                    : sections?.data.sections.map(section => ({
                                          value: section.id,
                                          label:
                                              section.translations?.find(
                                                  item =>
                                                      item.locale === locale,
                                              )?.name ??
                                              section.translations?.[0].name,
                                      }))
                            }
                            onChange={value => {
                                setSectionValue(value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="px-6 bg-white  rounded-b-2xl max-w-full overflow-auto pb-4">
                <table className="w-full border-0">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 pe-2 py-2 w-8 "
                            ></th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.topic')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.students')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.averageScore')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.detail')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {progressByUnit?.data.data.topics.map(
                            (topic, index) => (
                                <AccordionRow
                                    key={index}
                                    topic={topic}
                                    index={index}
                                    totalStudents={
                                        progressByUnit.data.data.studentsCount
                                    }
                                    classId={classId}
                                    subjectId={subjectId}
                                />
                            ),
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};
